<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0"><tbody><tr>
                  <td align="left"><div align="center"><font color="#000000">					  </font>
                    <div align="center"><font size="+2">Copyright</font>
                      <center><font size="+2"></font>                        
                      </center> 
                      <font color="#000033"><br>
                      </font>
                      <div align="center"><font color="#000033"><br>
                      </font>
                        <table width="88%" height="115" border="0" align="center" cellpadding="5" bgcolor="#FFFFCC">
                          <tbody><tr>
                            <td height="65"><div align="center" class="style3">
                                <p><font face="Verdana, Arial, Helvetica, sans-serif">The WILD database is the property of Columbia University. This data can only be used for research or academic purposes. Any commercial use of the data whatsoever or incorporation of the data into a larger database intended for public distribution must be done with the explicit written consent of the WILD administrators. Click [&nbsp;<router-link to="/repository/WILD/request">here</router-link>&nbsp;] to request data.</font></p>
                            </div></td>
                          </tr>
                        </tbody></table>
                        <font color="#000033">                                                </font>
                                                </div>
                      <br>
                      <br>
                      <hr width="700" size="0">
                      <br>
</div>
                    <table width="100%" border="0">
  <tbody><tr>
    <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <router-link to="/repository/WILD">Back to WILD Home</router-link> ]</font></div></td>
  </tr>
</tbody></table>
<br>
<br>
<table width="100%" border="0" align="center">
  <tbody><tr>
    <td width="49%"><div align="center"><font color="#000033" size="2" face="Verdana, Arial, Helvetica, sans-serif">Contact: <a href="mailto:wild@cs.columbia.edu">wild@cs.columbia.edu</a></font></div></td>
  </tr>
</tbody></table>
<br>
                  </div></td>
                </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>